@use "../global" as global;
@use "../global/shortcut-functions" as f;

.c_footer {
  padding: 40px;
  color: global.$white;
  text-align: center;
  background: global.$yellow;

  @include global.mq(sp) {
    padding: f.sp(40);
  }
}
