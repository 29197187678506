// コンテンツの基本サイズ
// 各ブレイクポイントのmax-widthを定義
$break-points: (
  sp: 750,
  tb: 1000,
  pc: null,
) !default;

// vw変換時の基準のwidthを定義(デザインデータの横幅など)
$base-widths: (
  sp: 375,
  pc: 1920,
) !default;

// 色定義
$black: #191919;
$white: #fff;
$l-gray: #fbfbfb;
$gray: #b3b3b3;
$yellow: #f7db15;
$green: #006250;
