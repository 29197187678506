@use "../global" as global;
@use "../global/shortcut-functions" as f;

.c_header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100px;
  width: 100%;
  background: global.$white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

  .inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1120px;
    height: 100%;
    padding: 0 10px;
    margin: 0 auto;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 100px;
    a,
    img {
      display: block;
    }
  }

  .nav {
    ul {
      display: flex;
      justify-content: center;
    }
    a {
      position: relative;
      display: inline-block;
      padding: 5px 10px;
      margin: 0 20px;
      font-size: 14px;
      border-radius: 5px;
      line-height: 1.2;
      transition: background 0.3s, color 0.3s;
      &:hover {
        background: global.$black;
        color: global.$white;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
        width: 1px;
        height: 60%;
        background: global.$black;
      }
    }
    li:last-child {
      a::after {
        content: none;
      }
    }
  }

  @include global.mq(sp) {
    height: f.sp(70);

    .inner {
      display: flex;
      justify-content: space-between;
      padding: f.sp(15);
      margin: 0 auto;
    }

    .logo {
      position: static;
      top: 0;
      left: 0;
      transform: none;
      width: f.sp(60);
      a,
      img {
        display: block;
        width: 100%;
      }
    }

    .nav {
      ul {
        display: block;
      }
      li {
        display: none;
      }
      a {
        padding: f.sp(10 15);
        margin: 0;
        background: global.$yellow;
        color: global.$white;
        font-weight: bold;
        &:hover {
          background: global.$yellow;
        }
      }
      li:last-child {
        display: block;
        a::after {
          content: none;
        }
      }
    }
  }
}
