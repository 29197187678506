// font-family

$font-family-default:
  "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ P ゴシック", sans-serif;

$font-family-yu-gothic:
  "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;

$font-family-meiryo:
  "メイリオ", Meiryo, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ＭＳ P ゴシック", sans-serif;

$font-family-noto-sans:
  'Noto Sans JP', "メイリオ", Meiryo, sans-serif;
