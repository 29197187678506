@use "../global" as global;
@use "../global/shortcut-functions" as f;

.sec-inner {
  max-width: 1120px;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;

  @include global.mq(sp) {
    max-width: 1120px;
    padding: f.sp(0 15);
  }
}

.sec-title {
  text-align: center;
  .sec-title_en {
    font-size: 80px;
    line-height: 1.2;
    color: global.$yellow;
  }
  .sec-title_ja {
    font-size: 25px;
  }

  @include global.mq(sp) {
    .sec-title_en {
      font-size: f.sp(40);
    }
    .sec-title_ja {
      font-size: f.sp(20);
    }
  }
}

.btn-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99;
  img {
    display: block;
  }
  @include global.mq(sp) {
    bottom: f.sp(25);
    right: f.sp(15);
    width: f.sp(60);
  }
}
