@use "../global" as global;
@use "../global/shortcut-functions" as f;

.p_policy {
  .page-title {
    margin-top: 150px;
    padding-bottom: 20px;
    border-bottom: 1px solid global.$gray;
  }
  .lead {
    margin-top: 40px;
  }
  .list {
    margin-top: 40px;
    padding-bottom: 80px;

    dl {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid global.$gray;
      &:last-child {
        border-bottom: none;
      }
    }
    dt {
      font-size: 18px;
      font-weight: bold;
    }
    dd {
      margin-top: 10px;
    }
  }

  @include global.mq(sp) {
    .page-title {
      margin-top: f.sp(150);
      padding-bottom: f.sp(20);
    }
    .lead {
      margin-top: f.sp(40);
    }
    .list {
      margin-top: f.sp(40);

      dl {
        margin-top: f.sp(20);
        padding-bottom: f.sp(20);
      }
      dt {
        font-size: f.sp(18);
      }
      dd {
        margin-top: f.sp(10);
      }
    }
  }
}
