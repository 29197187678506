@use "../global" as global;
@use "../global/shortcut-functions" as f;

.p_top {
  .mv {
    padding: 100px 0;
    text-align: center;
    img {
      max-width: 535px;
    }
  }

  .philosophy {
    margin-top: 200px;
    .head {
      margin-top: 50px;
      text-align: center;
      font-size: 50px;
    }
    .lead {
      margin-top: 30px;
      text-align: center;
    }
  }
  // philosophy

  .company {
    margin-top: 200px;
    .list {
      margin-top: 70px;
      .item {
        display: flex;
        padding: 30px 15%;
        border-top: 1px solid #f4f4f4;
        &:last-child {
          border-bottom: 1px solid #f4f4f4;
        }
      }
      dt {
        width: 35%;
        font-weight: bold;
      }
    }
  }
  // company

  .access {
    margin-top: 100px;
    .map {
      margin-top: 80px;
      iframe {
        width: 100%;
        height: 500px;
      }
    }
    .address {
      max-width: 480px;
      margin: 30px auto 0;
    }
  }
  // access

  .recruit {
    margin-top: 200px;
    .lead {
      margin-top: 80px;
      text-align: center;
    }
  }
  // recruit

  .contact {
    margin-top: 100px;
    .lead {
      margin-top: 80px;
      text-align: center;
    }
    .red {
      color: red;
    }

    .form {
      margin-top: 40px;
      padding: 80px 100px;
      background: global.$l-gray;

      .form-block {
        display: flex;
        padding: 25px;
        border-bottom: 1px solid global.$gray;
        &.first {
          border-top: 1px solid global.$gray;
        }

        label {
          width: 300px;
        }
        .wpcf7-form-control-wrap {
          flex: 1;
        }
        select,
        input,
        textarea {
          padding: 5px;
          border: 1px solid global.$gray;
          background: global.$white;
        }
        input,
        textarea {
          width: 100%;
        }
        select {
          width: 40%;
        }
      }

      .link {
        margin-top: 40px;
        text-align: center;
        a {
          color: global.$green;
          text-decoration: underline;
        }
      }
      .submit {
        width: 300px;
        margin: 40px auto 0;
        background: #000;
        .wpcf7-spinner {
          display: none;
        }
        .submit-link {
          width: 100%;
          display: block;
          line-height: 50px;
          color: global.$white;
        }
      }
    }
  }
  // contact

  @include global.mq(sp) {
    .mv {
      padding: f.sp(150 0);
      img {
        max-width: inherit;
        width: f.sp(300);
      }
    }

    .philosophy {
      margin-top: f.sp(100);
      .head {
        margin-top: f.sp(40);
        font-size: f.sp(30);
      }
      .lead {
        margin-top: f.sp(10);
      }
    }
    // philosophy

    .company {
      margin-top: f.sp(100);
      .list {
        margin-top: f.sp(40);
        .item {
          display: block;
          padding: f.sp(20 0);
          text-align: center;
        }
        dt {
          width: 100%;
        }
        dd {
          margin-top: f.sp(10);
        }
      }
    }
    // company

    .access {
      margin-top: f.sp(100);
      .map {
        margin-top: f.sp(40);
        iframe {
          width: 100%;
          height: f.sp(500);
        }
      }
      .address {
        max-width: inherit;
        margin: f.sp(30);
        padding: f.sp(0 30);
      }
    }
    // access

    .recruit {
      margin-top: f.sp(100);
      .lead {
        margin-top: f.sp(40);
      }
    }
    // recruit

    .contact {
      margin-top: f.sp(100);
      .lead {
        margin-top: f.sp(40);
        text-align: left;
      }

      .form {
        margin-top: f.sp(40);
        padding: f.sp(40 15);

        .form-block {
          display: block;
          padding: f.sp(20 10);
          border-bottom: 1px solid global.$gray;
          &.first {
            border-top: 1px solid global.$gray;
          }

          label {
            display: block;
            width: 100%;
            margin-bottom: f.sp(10);
          }
          select,
          input,
          textarea {
            padding: f.sp(5);
          }
          select {
            width: 100%;
          }
        }

        .link {
          margin-top: 40px;
          text-align: center;
          a {
            color: global.$green;
            text-decoration: underline;
          }
        }
        .submit {
          width: f.sp(300);
          margin: f.sp(40 auto 0);
          .submit-link {
            line-height: f.sp(50);
          }
        }
      }
    }
    // contact
  }
}
