@use "ress";
@use "responsive";
@use "../global" as global;
@use "../global/shortcut-functions" as f;

@include global.selection(global.$yellow, global.$black);
html {
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
}

body {
  font-family: global.$font-family-yu-gothic;
  font-size: 16px;
  line-height: 1.8;
  color: global.$black;
  @include global.mq(sp) {
    font-size: f.sp(14);
    &.is-opened {
      overflow: hidden;
    }
  }
}

main {
  margin-top: 100px;
  @include global.mq(sp) {
    margin-top: f.sp(70);
  }
}

a {
  color: global.$black;
  text-decoration: none;
  // transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
  // &:hover {
  // opacity: 0.7;
  // }
}

.f-mont {
  font-family: "Montserrat", global.$font-family-yu-gothic;
  font-weight: 100;
  letter-spacing: 0.1em;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  @include global.mq(sp) {
    max-width: inherit;
    width: 100%;
  }
}
