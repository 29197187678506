@use "../global" as global;
@use "../global/shortcut-functions" as f;

.p_contact {
  text-align: center;
  main {
    min-height: calc(100vh - 210px);
  }
  .head {
    padding-top: 100px;
  }
  .lead {
    margin-top: 50px;
  }
  .link {
    display: block;
    color: global.$white;
    background-color: #000;
    width: 300px;
    line-height: 50px;
    margin: 50px auto 0;
  }

  @include global.mq(sp) {
    main {
      min-height: 76vh;
    }
    .head {
      padding-top: f.sp(100);
    }
    .lead {
      margin-top: f.sp(50);
    }
    .link {
      width: f.sp(300);
      line-height: f.sp(50);
      margin: f.sp(50 auto 100);
    }
  }
}
